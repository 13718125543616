import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export const defaultOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "6-month projection",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const defaultData = {
  labels,
  datasets: [
    {
      type: "line",
      label: "Historical min",
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 2,
      backgroundColor: "rgb(255, 99, 132)",
      data: [8, 8, 8, 8, 8, 8, 8],
      fill: false,
    },
    {
      type: "line",
      label: "Historical max",
      borderColor: "rgb(75, 192, 192)",
      borderWidth: 2,
      backgroundColor: "rgb(75, 192, 192)",
      data: [84, 84, 84, 84, 84, 84, 84],
      fill: false,
    },
    {
      type: "bar",
      label: "Dataset",
      backgroundColor: "rgb(53, 162, 235)",
      data: [60, 55, 60, 63, 68, 70, 75],
    },
  ],
};

const MultitypeChartComponent = ({
  data = defaultData,
  options = defaultOptions,
  chartType = "line",
  plugins,
}) => {
  return (
    <Chart options={options} type={chartType} data={data} plugins={plugins} />
  );
};

export default MultitypeChartComponent;
