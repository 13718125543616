export const fieldInitialValues = {
  id: null,
  name: "",
  polygons: [
    { id: null, position: [], color: "blue", popup: null, tooltip: null },
  ],
  irrigations_system: {
    system: "",
  },
  soil_type: {
    clay: "",
    silt: "",
    sand: "",
    field_capacity: "",
    permanent_wilting_point: "",
    bulk_density: "",
    soil_porosity: "",
    climate_zone: "",
    water_content_saturation: "",
    water_content_saturation_by_sensor: "",
    saturated_hydraulic_conductivity: "",
  },
  crop: {
    type: "",
    species: "",
    variety_name: "",
    variety_code: "",
    distance_line: "",
    distance_individuals: "",
    planting_date: "",
    flowering_date: "",
    rieping_date: "",
    expected_harvest_date: "",
    real_harvest_date: "",
    crop_yelds: "",
  },
  sensors: [],
  activities: {
    irrigation: [],
    soil_preparation: [],
    fertilizer: [],
    pesticide: [],
  },
  realtime: {
    irrigation_profile: {},
    transpiration_profile: {},
    suggested_irrigation: {},
    sensors_data: [],
  },
};

export const irrigationSystem = {
  sprinkler: "sprinkler",
  drip: "drip",
  suterranean: "suterranean",
};

export const soilType = {
  texture: "texture",
  field_capacity: "field_capacity",
  permanent_wilting_point: "permanent_wilting_point",
  bulk_density: "bulk_density",
  soil_porosity: "soil_porosity",
  climate_zone: "climate_zone",
  water_content_saturation: "water_content_saturation",
  water_content_saturation_by_sensor: "water_content_saturation_by_sensor",
  saturated_hydraulic_conductivity: "saturated_hydraulic_conductivity",
};

export const cropType = {
  annual: "annual",
  perennial: "perennial",
};

export const speciesType = {
  tomato: "tomato",
  maize: "maize",
  potato: "potato",
  orange: "orange",
  onion: "onion",
  wheat: "wheat",
  rice: "rice",
  barley: "barley",
  sorghum: "sorghum",
  soybean: "soybean",
  sunflower: "sunflower",
  lettuce: "lettuce",
  carrot: "carrot",
  watermelon: "watermelon",
  citrus: "citrus",
  almond: "almond",
  date_palm: "date_palm",
  peach: "peach",
  wine_grapes: "wine_grapes",
  olives: "olives",
  cotton: "cotton",
  alfalfa: "alfalfa",
  pastures: "pastures",
};

export const cropAnnualKeys = [
  "type",
  "species",
  "variety_name",
  "variety_code",
  "distance_line",
  "distance_individuals",
  "planting_date",
  "flowering_date",
  "rieping_date",
  "expected_harvest_date",
  "real_harvest_date",
  "crop_yelds",
];

export const cropPerennialKeys = [
  "type",
  "species",
  "variety_name",
  "variety_code",
  "distance_line",
  "distance_individuals",
  "planting_year",
  "flowering_date",
  "rieping_date",
  "expected_harvest_date",
  "real_harvest_date",
  "crop_yelds",
];

export const soilTypeKeys = [
  "clay",
  "silt",
  "sand",
  "field_capacity",
  "permanent_wilting_point",
  "bulk_density",
  "soil_porosity",
  "climate_zone",
  "water_content_saturation",
  "water_content_saturation_by_sensor",
  "saturated_hydraulic_conductivity",
];

export const irrigationsSystemKeys = ["system"];

export const irrigationsSystemSprinklerKeys = ["system", "number", "amount"];

export const irrigationsSystemDriprKeys = [
  "system",
  "number_driping_pipes",
  "space_driping_bores",
  "water_dispensed_bore",
  "wetted_length",
];

export const irrigationsSystemSuterraneanKeys = [
  "system",
  "depth_pipes",
  "number_driping_pipes",
  "amount",
  "water_dispensed_bore",
  "wetted_length",
];

export const climateZoneOptions = {
  mediterrenean: "Mediterrenean",
  arid: "Arid",
};
