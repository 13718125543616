import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//import BarChartComponent from "shared-components/src/components/charts/barChart.component";
import LineChartComponent from "shared-components/src/components/charts/lineChart.component";
import CustomizedTables, {
  createData,
} from "shared-components/src/components/displayData/CustomizedTables.component";
import FieldSelectorComponent from "../components/fieldSelector/FieldSelector.component";
//import SuggestedIrrigationComponent from "../components/suggestedIrrigation/SuggestedIrrigation.component";
import { fetchSensorsMeasureRequest } from "../store/actions/realtime.actions";
import dayjs from "dayjs";
import { fetchSensorMeasureDataRequest } from "../store/actions/realtimeSensorMeasure.actions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import IrrigationActionsForm from "../components/irrigationActionsForm/IrrigationActionsForm.component";
import MultitypeChartComponent from "shared-components/src/components/charts/multitypeChart.component";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import {
  data,
  options,
  verticalLinePlugin,
} from "shared-components/src/components/charts/utils/realtimeChartData";

const RealtimePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width: 767px");
  const fieldSelected = useSelector((state) => state.field.data);
  const fields = useSelector((state) => state.fields.data);
  const field = fields.find((f) => f.id === fieldSelected);
  const sensors_data = useSelector((state) => state.sensorsMeasure.data);
  const [sensors, setSensorValue] = useState(
    sensors_data ? convertDataValues(sensors_data) : null
  );
  const irrigation_profile = field?.realtime?.irrigation_profile ?? null;
  const transpiration_profile = field?.realtime?.transpiration_profile ?? null;
  const [selectedSensor, setSelectedSensorValue] = useState(undefined);
  const [selectedMeasure, setSelectedMeasureValue] = useState(undefined);

  const start_time = new Date();
  start_time.setDate(start_time.getDate() - 14);
  const end_time = new Date();
  const [startDate, setStartDateValue] = useState(start_time);
  const [endDate, setEndDateValue] = useState(end_time);

  const sensorMeasureData = useSelector(
    (state) => state.sensorMeasureData.data
  );

  const sensorChartData = Array.isArray(sensorMeasureData)
    ? {
        labels: getlabels(sensorMeasureData),
        datasets: getDatasets(sensorMeasureData),
      }
    : null;

  function getlabels(sensorData) {
    if (Array.isArray(sensorData)) {
      const sensorDataReverse = sensorData.slice().reverse();
      return sensorDataReverse.map((data) =>
        dayjs(data.time_of_measure).format("DD/MM/YYYY HH:mm")
      );
    }
  }

  function getDatasets(sensorData) {
    if (Array.isArray(sensorData)) {
      const sensorDataReverse = sensorData.slice().reverse();
      const label = sensorData?.[0]?.unit_of_measurement;
      const data = sensorDataReverse.map((data) => data.value);
      const datasets = [
        {
          label: label,
          data: data,
          borderColor: "rgb(75, 192, 192)",
          borderWidth: 2,
          backgroundColor: "rgb(75, 192, 192)",
        },
      ];
      return datasets;
    }
  }

  useEffect(() => {
    if (field) {
      dispatch(fetchSensorsMeasureRequest(field.name));
      setSensorValue(undefined);
      setSelectedSensorValue(undefined);
      setSelectedMeasureValue(undefined);
    }
  }, [field]);

  useEffect(() => {
    setSensorValue(sensors_data ? convertDataValues(sensors_data) : null);
  }, [sensors_data]);

  const handleSensorChange = (event) => {
    const sensorID = event.target.value;
    setSelectedSensorValue(sensorID);
    setSelectedMeasureValue(undefined);
  };

  const handleMeasureChange = (event) => {
    const datastream_name = event.target.value;
    setSelectedMeasureValue(datastream_name);
    measureDataRequest(datastream_name, startDate, endDate);
  };

  const measureDataRequest = (datastream_name, start_time, end_time) => {
    if (field) {
      dispatch(
        fetchSensorMeasureDataRequest({
          field: field.name,
          datastream_name: datastream_name,
          start_time: start_time.toISOString(),
          end_time: end_time.toISOString(),
        })
      );
    }
  };

  const setStartDate = (date) => {
    setStartDateValue(date);
  };

  const setEndDate = (date) => {
    setEndDateValue(date);
  };

  useEffect(() => {
    measureDataRequest(selectedMeasure, startDate, endDate);
  }, [startDate, endDate]);

  function getUnitOfMeasurment(unit) {
    const match = unit?.match(/\(([^)]+)\)/);
    if (match) {
      return match[1];
    }
    return;
  }

  function convertDataValues(data) {
    const dataReduce =
      data.status === 404
        ? []
        : data.reduce((sensors, sensor) => {
            if (sensor) {
              const sensorData = {
                label: sensor?.property_name ?? "no-label",
                value:
                  sensor?.value +
                  getUnitOfMeasurment(sensor?.unit_of_measurement),
                datastream_name: sensor?.datastream_name,
              };

              if (sensors.length === 0) {
                sensors.push({
                  id: sensor.deviceID,
                  sensor_name: sensor.deviceID,
                  data: [sensorData],
                });
              } else {
                const existingSensorIndex = sensors.findIndex(
                  (item) => item.id === sensor.deviceID
                );
                if (existingSensorIndex === -1) {
                  sensors.push({
                    id: sensor.deviceID,
                    sensor_name: sensor.deviceID,
                    data: [sensorData],
                  });
                } else {
                  sensors[existingSensorIndex].data.push(sensorData);
                }
              }
            }

            return sensors;
          }, []);
    return dataReduce;
  }

  function createRowData(data) {
    return data.map((d) => createData(d.label, d.value));
  }

  function getFilteredSensors(sensors) {
    if (!selectedSensor) {
      return sensors;
    }
    return sensors.filter((sensor) => sensor.id === selectedSensor);
  }

  const barChartLabels = irrigation_profile?.labels ?? [];

  const barChartData = {
    labels: barChartLabels,
    datasets: irrigation_profile?.datasets ?? [],
  };

  const linearChartLabels = transpiration_profile?.labels ?? [];

  const linearChartData = {
    labels: linearChartLabels,
    datasets: transpiration_profile?.datasets ?? [],
  };

  const readDate = (date) => {
    const value = dayjs(new Date(date));
    return value;
  };

  return (
    <Fragment>
      <h3>{t("real-time-page.title")}</h3>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: "20px" }}
      >
        {isMobile ? null : (
          <Grid item xs={12} sm={9} md={9}>
            <Typography>
              <span>{t("real-time-page.subtitle")}</span>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={3} md={3}></Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ height: "100%", padding: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <FieldSelectorComponent></FieldSelectorComponent>
              </div>
            </div>
          </Paper>
        </Grid>
        {fieldSelected && (
          <Grid item xs={12}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12}>
                    <Paper
                      variant="outlined"
                      sx={{ height: "100%", padding: "20px" }}
                    >
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <h4>{t("real-time-page.irrigation-profile")}</h4>
                          <MultitypeChartComponent
                            options={options}
                            data={data}
                            plugins={[verticalLinePlugin]}
                          />
                          {/* {irrigation_profile && (
                            <BarChartComponent
                              data={barChartData}
                            ></BarChartComponent>
                          )} */}
                        </Grid>
                      </Grid>
                      <Paper>
                        <IrrigationActionsForm />
                      </Paper>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper
                      variant="outlined"
                      sx={{ height: "100%", padding: "20px" }}
                    >
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <h3>{t("real-time-page.transpiration-profile")}</h3>
                          <Box
                            width="auto"
                            height="400px"
                            border="0.5px solid lightgrey"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="10px"
                          >
                            <Chip
                              icon={<PrecisionManufacturingIcon />}
                              label="Under development"
                              variant="outlined"
                              color="warning"
                            />
                          </Box>
                          {/* <MultitypeChartComponent></MultitypeChartComponent> */}
                          {/* {transpiration_profile && (
                            <LineChartComponent
                              data={linearChartData}
                            ></LineChartComponent>
                          )} */}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Paper
                  variant="outlined"
                  sx={{ height: "100%", padding: "20px" }}
                >
                  <h3>{t("real-time-page.sensor-data")}</h3>
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    style={{ marginTop: "20px", marginBottom: "30px" }}
                  >
                    <Grid item xs={3} sm={3} md={3}>
                      <FormControl fullWidth>
                        <InputLabel id="sensor-select-label">
                          {t("real-time-page.input_label_sensor")}
                        </InputLabel>
                        <Select
                          labelId="sensor-select-label"
                          id="sensor-select"
                          label="Sensor"
                          value={selectedSensor}
                          onChange={handleSensorChange}
                        >
                          {/* <MenuItem key={"all-sensors"} value={"all-sensors"}>
                        All sensors
                      </MenuItem> */}
                          {sensors &&
                            sensors.map((option, i) => (
                              <MenuItem key={i} value={option.id}>
                                {option.sensor_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                      <FormControl fullWidth>
                        <InputLabel id="measure-select-label">
                          {t("real-time-page.input_label_measure")}
                        </InputLabel>
                        <Select
                          labelId="measure-select-label"
                          id="measure-select"
                          label="Measure"
                          value={selectedMeasure}
                          onChange={handleMeasureChange}
                        >
                          {sensors &&
                            sensors
                              .find((sensor) => sensor.id === selectedSensor)
                              ?.data.map((option, i) => (
                                <MenuItem
                                  key={i}
                                  value={option.datastream_name}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={t("real-time-page.date_picker.start")}
                          name="startDate"
                          value={readDate(startDate)}
                          format="DD/MM/YYYY"
                          onChange={(date) => setStartDate(date)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={t("real-time-page.date_picker.end")}
                          name="endDate"
                          value={readDate(endDate)}
                          format="DD/MM/YYYY"
                          onChange={(date) => setEndDate(date)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  {selectedMeasure && sensorChartData && (
                    <div>
                      <Grid item xs={12}>
                        <LineChartComponent
                          data={sensorChartData}
                        ></LineChartComponent>
                      </Grid>
                    </div>
                  )}
                  {sensors &&
                    getFilteredSensors(sensors).map((sensor) => (
                      <div key={sensor.id} style={{ marginTop: "30px" }}>
                        <CustomizedTables
                          rows={createRowData(sensor.data)}
                          tableTitle={sensor.sensor_name}
                        ></CustomizedTables>
                      </div>
                    ))}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default RealtimePage;
