import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { irrigationSystem } from "../../config/fieldIntialValues";

const IrrigationFormComponent = (props) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = props?.formikProps;
  const [system, setSystem] = React.useState(
    values?.irrigations_system?.system
  );

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    if (newValue !== system) {
      setSystem(newValue);
      setFieldValue("irrigations_system", {});
      setFieldValue("irrigations_system.system", newValue);
    }
  };

  return (
    <Fragment>
      <div>
        <h4>{t("irrigation_form.h4")}</h4>
      </div>
      <p>{t("irrigation_form.p")}</p>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="system-select-label">
              {t("irrigation_form.input_label")}
            </InputLabel>
            <Select
              label={t("irrigation_form.select_label")}
              id="irrigations_system.system"
              name="irrigations_system.system"
              labelId="system-select-label"
              value={values?.irrigations_system?.system}
              onChange={handleSelectChange}
            >
              <MenuItem value="">
                <em>{t("none")}</em>
              </MenuItem>
              {Object.values(irrigationSystem).map((option, i) => (
                <MenuItem key={i} value={option}>
                  {t(option)}
                </MenuItem>
              ))}
            </Select>
            <ErrorMessage name="irrigations_system.system" component="div" />
          </FormControl>
        </Grid>

        {irrigationSystem.sprinkler === system && (
          <Fragment>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                type="number"
                inputProps={{ min: 0 }}
                as={TextField}
                label={t("irrigation_form.field_label_sprinklers_numb")}
                name="irrigations_system.number"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage name="irrigations_system.number" component="div" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                type="number"
                inputProps={{ min: 0, step: "any" }}
                as={TextField}
                label={t(
                  "irrigation_form.field_label_spinkerler_water_dispers"
                )}
                name="irrigations_system.amount"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage name="irrigations_system.amount" component="div" />
            </Grid>
          </Fragment>
        )}

        {irrigationSystem.drip === system && (
          <Fragment>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                type="number"
                inputProps={{ min: 1, max: 2 }}
                as={TextField}
                label={t("irrigation_form.field_label_driping_pipes")}
                name="irrigations_system.number_driping_pipes"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage
                name="irrigations_system.number_driping_pipes"
                component="div"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                type="number"
                inputProps={{ min: 0 }}
                as={TextField}
                label={t("irrigation_form.field_label_driping_space")}
                name="irrigations_system.space_driping_bores"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage
                name="irrigations_system.space_driping_bores"
                component="div"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                type="number"
                inputProps={{ min: 0 }}
                as={TextField}
                label={t("irrigation_form.field_label_water_dispens")}
                name="irrigations_system.water_dispensed_bore"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage
                name="irrigations_system.water_dispensed_bore"
                component="div"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                type="number"
                inputProps={{ min: 0 }}
                as={TextField}
                label={t("irrigation_form.field_label_wetted_length")}
                name="irrigations_system.wetted_length"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage
                name="irrigations_system.wetted_length"
                component="div"
              />
            </Grid>
          </Fragment>
        )}

        {irrigationSystem.suterranean === system && (
          <Fragment>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                type="number"
                inputProps={{ min: 0 }}
                as={TextField}
                label={t("irrigation_form.field_label_depth_pipes")}
                name="irrigations_system.depth_pipes"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage
                name="irrigations_system.depth_pipes"
                component="div"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                type="number"
                inputProps={{ min: 1, max: 2 }}
                as={TextField}
                label={t("irrigation_form.field_label_driping_pipes")}
                name="irrigations_system.number_driping_pipes"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage name="irrigations_system.amount" component="div" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                type="number"
                inputProps={{ min: 0 }}
                as={TextField}
                label={t("irrigation_form.field_label_driping_space")}
                name="irrigations_system.amount"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage
                name="irrigations_system.number_driping_pipes"
                component="div"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                type="number"
                inputProps={{ min: 0 }}
                as={TextField}
                label={t("irrigation_form.field_label_water_dispens")}
                name="irrigations_system.water_dispensed_bore"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage
                name="irrigations_system.water_dispensed_bore"
                component="div"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Field
                type="number"
                inputProps={{ min: 0 }}
                as={TextField}
                label={t("irrigation_form.field_label_wetted_length")}
                name="irrigations_system.wetted_length"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage
                name="irrigations_system.wetted_length"
                component="div"
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
};

export default IrrigationFormComponent;
