export const data = {
  labels: [
    "12 ott",
    "13 ott",
    "14 ott",
    "15 ott",
    "16 ott",
    "17 ott",
    "18 ott",
    "19 ott",
    "20 ott",
    "21 ott",
    "22 ott",
  ],
  datasets: [
    {
      type: "line",
      label: "Suggested full irrigation",
      borderColor: "rgba(44,108,140,255)",
      borderWidth: 2,
      backgroundColor: "rgba(44,108,140,255)",
      data: [
        51.18, 52.78, 57.5, 60.71, 63.79, 66.5, 69.38, 72.35, 73.37, 37.41,
      ],
      fill: false,
    },
    {
      type: "line",
      label: "Suggested deficit irrigation",
      borderColor: "rgba(234,123,69,255)",
      borderWidth: 2,
      backgroundColor: "rgba(234,123,69,255)",
      data: [
        35.82, 36.95, 40.22, 42.49, 44.65, 46.55, 48.57, 50.64, 51.36, 26.19,
      ],
      fill: false,
    },
    {
      type: "line",
      label: "Soil moisture (%)",
      backgroundColor: "rgba(63,124,152,255)",
      borderColor: "rgba(63,124,152,255)",
      borderDash: [5, 5],
      data: [
        17.87, 17.68, 16.94, 16.12, 15.67, 15.26, 14.9, 14.51, 16.53, 20.53,
      ],
      fill: false,
    },
    {
      type: "line",
      label: "Today",
      backgroundColor: "rgba(43,72,54,255)",
      borderColor: "rgba(43,72,54,255)",
      fill: false,
    },
    {
      type: "line",
      label: "Delated date",
      backgroundColor: "rgba(239,157,118,255)",
      borderColor: "rgba(239,157,118,255)",
      fill: false,
    },
    {
      type: "line",
      label: "Deficit date",
      backgroundColor: "rgba(243,41,36,255)",
      borderColor: "rgba(243,41,36,255)",
      fill: false,
    },
  ],
};

export const verticalLinePlugin = {
  id: "verticalLinePlugin",
  afterDraw: (chart) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y;

    const verticalLines = [
      { label: "15 ott", color: "rgba(43,72,54,255)" },
      { label: "16 ott", color: "rgba(239,157,118,255)" },
      { label: "20 ott", color: "rgba(243,41,36,255)" },
    ];

    ctx.save();

    verticalLines.forEach(({ label, color }) => {
      const xIndex = data.labels.indexOf(label);
      if (xIndex === -1) return;

      const x = xAxis.getPixelForValue(xIndex);

      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 2;
      ctx.strokeStyle = color;
      ctx.stroke();
    });

    ctx.restore();
  },
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "Irrigation activities",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";
          if (label) {
            label += ": ";
          }
          if (context.raw !== null) {
            label += context.raw + " m³";
          }
          return label;
        },
      },
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: "Quantity of water (m³)",
      },
      min: 0,
    },
    yRight: {
      position: "right",
      title: {
        display: true,
        text: "Soil moisture (%)",
      },
      min: 0,
      max: 100,
      ticks: {
        stepSize: 10,
      },
    },
  },
};
